<template>  
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <div class="card-header text-center">
            <img src="/img/logo.svg" alt="Logo" class="brand-logo my-3"/>
            <h3 class="m-0">Mi POA</h3>
            <small>Content Management System</small>
          </div>
          <div class="text-center"><small><button class="btn btn-primary float-right" @click="goToEvents()">Login User</button></small></div>
          <h4>Registrar Usuario</h4>
        </div>
        <form @submit.prevent="registerPersona">
          <div class="card-body">
            <FormText
              label="Nombre"
              v-model="form.first_name"
            />
            <FormText
              label="Apellido"
              v-model="form.last_name"
            />
            <FormText
              label="Email"
              v-model="form.email"
            />
            <FormText
              label="Password"
              password
              v-model="form.password"
            />
            <FormText
              label="Repetir Password"
              password
              v-model="form.password_confirmation"
            />

          </div>
          <div class="card-footer">
            <button class="btn btn-primary btn-block" type="submit">
              Registrase
            </button>
          </div>
          <div class="text-center">
            <small>
              by <a href="http://trememote.com.ar" target="_blank">&nbsp;Trememote</a>
            </small>
          </div>
        </form>
      </div>
      
    </div>
  </div>
</template>

<script>
import FormText from "@/components/Form/FormText.vue";

export default {
  components: {
    FormText,
  },
  data: () => ({
    form: {
      first_name: null,
      last_name: null, 
      email: null,
      password: null,
      password_confirmation: null,
      persona_id: null,
      nombre: null,
    },
  }),
  beforeMount() {
    this.$store.dispatch("setCredentials");
    const isLoggedIn = this.$store.getters.isLoggedIn;

    if (isLoggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    // registerPersona() {
    //   this.form.nombre = `${this.form.first_name} ${this.form.last_name}`
    //   this.$store
    //       .dispatch("registerPersona", this.form).then((id) => {
    //       this.registerUser(id);
    //     });
    // },
    // registerUser(id){
    //   this.form.persona_id = id
    //   this.$store
    //       .dispatch("registerUser", this.form).then(() => {
    //       this.$store.dispatch('logout')
    //     })
    // },
    registerPersona() {
      this.$store
          .dispatch("registerUser", this.form).then(() => {
          this.$store.dispatch('logout')
        })
    },
    goToEvents: function () {
        this.$router.push('/login')
    }
  },
};
</script>
